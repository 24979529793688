<!--
 * @Description: 编辑产品的属性
 * @Author: 琢磨先生
 * @Date: 2022-06-02 02:12:50
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-02 02:12:58
-->
<template>
    <div>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>